.hip-modal a {
  color: #ff344f;
}

.hip .lt-listing-search-form .search-form-content .search-form-fields.cols-2.has_search_keyword > div.search_keywords {
  width: 50%;
}

.hip .lt-listing-search-form .search-form-content {
  width: 100%;
  padding-right: 0;
}

.hip .lt-listing-search-form .search-form-content .search-form-fields.cols-2.has_search_keyword > div:not(.search_keywords) {
  width: 40%;
}

.hip .filters {
  width: 40%;
  padding-top: .3rem;
  display: block;
}

.hip .lt-listing-search-form .search-form-content .search-form-fields {
  width: 100%;
  justify-content: space-between;
}

.selects-row {
  width: 100%;
  flex-direction: row;
  display: flex;
}

.modal-wrapper {
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  background-color: rgba(0, 0, 0, .64);
  align-items: center;
  justify-items: center;
  display: grid;
  position: fixed;
  top: 0;
}

.elementor img.list-icon {
  object-fit: contain;
  width: 80px;
  height: 80px;
}

.modal-items-links {
  align-items: center;
  display: flex;
}

.modal-wrapper .modal-content {
  min-height: 20vh;
  max-width: 90vw;
  width: 90vw;
  max-height: 80vh;
  background-color: #fff;
  flex-direction: column;
  margin-top: 2rem;
  display: flex;
  overflow: scroll;
}

.modal-content .content {
  flex: 1 0;
  padding: 1rem;
}

.modal-content .content .title {
  width: 100%;
  justify-content: space-between;
}

.modal-content .content .title a {
  height: 2rem;
  background-color: #fff;
  border-radius: 4px;
  margin: .5rem;
  padding: 0 .5rem;
}

.modal-content .content .title a:hover {
  background-color: #e0e0e0;
}

.headshoot {
  width: 100%;
  height: auto;
  justify-content: center;
  padding: 1rem;
  display: flex;
}

.text-container {
  padding: 0;
}

.text-container p {
  padding-bottom: 1rem;
}

.headshoot img {
  width: 100%;
  object-fit: contain;
  height: auto;
  border-top-left-radius: 4px;
}

@media (min-width: 720px) {
  .modal-wrapper .modal-content {
    max-width: 60vw;
    width: 60vw;
    flex-direction: row;
  }

  .headshoot {
    width: 30%;
    display: inline-block;
  }

  .modal-content .content .title {
    max-width: 40vw;
  }
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: row;
}

.hip-modal a {
  color: #ff344f;
}

.hip .lt-listing-search-form .search-form-content .search-form-fields.cols-2.has_search_keyword > div.search_keywords {
  width: 50%;
}

.hip .lt-listing-search-form .search-form-content {
  width: 100%;
  padding-right: 0;
}

.hip .lt-listing-search-form .search-form-content .search-form-fields.cols-2.has_search_keyword > div:not(.search_keywords) {
  width: 40%;
}

.hip .filters {
  width: 40%;
  padding-top: .3rem;
  display: block;
}

.hip .lt-listing-search-form .search-form-content .search-form-fields {
  width: 100%;
  justify-content: space-between;
}

.selects-row {
  width: 100%;
  flex-direction: row;
  display: flex;
}

.modal-wrapper {
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  background-color: rgba(0, 0, 0, .64);
  align-items: center;
  justify-items: center;
  display: grid;
  position: fixed;
  top: 0;
}

.elementor img.list-icon {
  object-fit: contain;
  width: 80px;
  height: 80px;
}

.modal-items-links {
  align-items: center;
  display: flex;
}

.modal-wrapper .modal-content {
  min-height: 20vh;
  max-width: 90vw;
  width: 90vw;
  max-height: 80vh;
  background-color: #fff;
  flex-direction: column;
  margin-top: 2rem;
  display: flex;
  overflow: scroll;
}

.modal-content .content {
  flex: 1 0;
  padding: 1rem;
}

.modal-content .content .title {
  width: 100%;
  justify-content: space-between;
}

.modal-content .content .title a {
  height: 2rem;
  background-color: #fff;
  border-radius: 4px;
  margin: .5rem;
  padding: 0 .5rem;
}

.modal-content .content .title a:hover {
  background-color: #e0e0e0;
}

.headshoot {
  width: 100%;
  height: auto;
  justify-content: center;
  padding: 1rem;
  display: flex;
}

.text-container {
  padding: 0;
}

.text-container p {
  padding-bottom: 1rem;
}

.headshoot img {
  width: 100%;
  object-fit: contain;
  height: auto;
  border-top-left-radius: 4px;
}

@media (min-width: 720px) {
  .modal-wrapper .modal-content {
    max-width: 60vw;
    width: 60vw;
    flex-direction: row;
  }

  .headshoot {
    width: 30%;
    display: inline-block;
  }

  .modal-content .content .title {
    max-width: 40vw;
  }
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: row;
}

/*# sourceMappingURL=index.173a9ed5.css.map */
